.n-tagline {
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;
}

.pricingtable-wrapper.active .slot-title {
  color: white !important;
}

.custom-logo img {
  width: 130px !important;
  margin-top: -10px;
}

.founder-img {
  max-height: 400px !important;
  border-radius: 20px;
  margin-bottom: 20px;
}

.custom-spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 500px) {
  .currancy-wrapper.home-currancy {
    margin-top: 0px;
  }

  .accept-curr {
    margin-top: 30px;
    color: #1c2e9e !important;
  }

  .n-tagline {
    top: 1px !important;
    right: 1px !important;
  }
}
